import React from 'react'
import Layout from '../templates/layout'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Link } from 'gatsby'
import { SEO, Heading, Text } from '../components/index'
import { BackToTop, ArrowLeft, WaveBlue } from '../components/icons/index'

const Page = ({ mdx }) => {
  return (
    <Layout id="top" bg="tertiary">
      <SEO title={mdx.frontmatter.title || 'Work with me'} />
      <div className="grid site-top">
        <div className="row">
          <div className="col col-12-xs col-0-xs-offset col-8-md col-2-md-offset">
            <div className="row row-xs-middle">
              <Link to="/" style={{ marginRight: '2rem' }}>
                <ArrowLeft />
              </Link>
              <Heading size="large">{mdx.frontmatter.title || 'Work with me'}</Heading>
            </div>
            <div>
              {/* dont put the content inside a flex container !! */}
              {mdx.body ? (
                <MDXRenderer>{mdx.body}</MDXRenderer>
              ) : (
                <Text size="medium">Content could not be loaded.</Text>
              )}
            </div>
          </div>
        </div>
        {mdx.body && (
          <div className="row">
            <div className="col col-12-xs col-0-xs-offset col-8-md col-2-md-offset pt3-xs pt6-md pb6-md">
              <WaveBlue />
            </div>
            <div className="col col-12-xs col-0-xs-offset col-8-md col-2-md-offset">
              <BackToTop to="#top" />
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Page
